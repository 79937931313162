<template>
  <div class="money">
    <el-row style="display: flex;align-items:center;">
      <span class="title">付费发布项目支付金币数</span>
      <span class="gold">{{ priceList.length ? priceList[1].points : 0 }}</span>
    </el-row>
    <el-row style="display: flex;align-items:center;margin-top:56px;">
      <span class="title">付费发布项目解锁沟通支付金币数</span>
      <div style="position: relative;">
        <span class="option">选项一</span>
        <span class="gold">{{ priceList.length ? priceList[2].points : 0 }}</span>
      </div>
      <div style="position: relative;">
        <span class="option">选项二</span>
        <span class="gold">{{ priceList.length ? priceList[3].points : 0 }}</span>
      </div>
      <div style="position: relative;">
        <span class="option">选项三</span>
        <span class="gold">{{ priceList.length ? priceList[4].points : 0 }}</span>
      </div>
    </el-row>
    <el-row style="display: flex;align-items:center;margin-top:56px;">
      <span class="title">免费发布项目解锁沟通金币数</span>
      <span class="gold">{{ priceList.length ? priceList[5].points : 0 }}</span>
    </el-row>
    <el-row style="display: flex;align-items:center;margin-top:56px;">
      <span class="title">个人主页解锁沟通金币数</span>
      <span class="gold">{{ priceList.length ? priceList[6].points : 0 }}</span>
    </el-row>
    <el-row style="display: flex;align-items:center;margin-top:90px;">
      <span class="title">成为高能经纪人支付金币数</span>
      <el-input v-model="agent" placeholder="请输入金币数" @blur="setPrice(priceList[0].id)" />
    </el-row>
  </div>
</template>

<script>
import { getPrice, setPrice } from '@/api/price'
export default {
  data() {
    return {
      agent: 0,
      priceList: []
    }
  },
  created() {
    this.getPrice()
  },
  methods: {
    getPrice() {
      getPrice().then(res => {
        if (res.data.list.length > 0) {
          this.priceList = res.data.list
          this.agent = res.data.list[0].points
        }
      })
    },
    setPrice(id) {
      setPrice({ id, points: this.agent }).then(() => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
 .money {
    padding: 100px;
    .title {
      display: block;
      font-size: 14px;
      color: #000;
      width: 230px;
      text-align: right;
    }
    .gold {
      display: block;
      width: 300px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      background: #cccccc;
      border: 1px solid #797979;
      margin-left: 36px;
    }
    .option {
      position: absolute;
      left: 38px;
      top: -24px;
    }
    &::v-deep {
      .el-input {
        width: 300px;
        height: 38px;
        margin-left: 36px;
      }
    }
 }
</style>
