import request from '@/utils/axios'

/** 获取价格 */
export function getPrice() {
  return request({
    url: '/price',
    method: 'get'
  })
}

/** 修改价格 */
export function setPrice(data) {
  return request({
    url: '/price',
    method: 'put',
    data
  })
}
